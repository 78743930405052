.root {
	width: 100%;
	height: 100%;
}
.root__loading {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background: #212121;
	color: white;
}

.transitionWrapper {
	width: 100%;
	height: 100%;
}

.toggle {
	position: fixed;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border: none;
	border-radius: 2px;
	background: rgba(0, 0, 0, .8);
	color: white;
	font-size: 20px;
	cursor: pointer;
}