.root {
	display: grid;
	width: 100%;
	height: 100%;
	background-image: url('./night.png');
	background-size: cover;
	background-position: center center;
}
.tile {
	background-image: url('./day.png');
	background-attachment: fixed;
	background-size: cover;
	background-position: center center;
}
.root__transition .tile {
	transition: opacity 5s ease;
}
