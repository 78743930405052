.root {
	position: relative;
	width: 100%;
	height: 100%;
}
.bgImage {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.canvas {
	position: absolute;
	top: 0;
	left: 0;
}
